<template>
  <div>
    <HomeFirstArticleComponent />

    <section>
      <article class="why_book">
        <div class="container text-center">
          <h2>Why book with Ihamba Adventures?</h2>
          <hr style="width: 100px; background-color: #996727;" />
          <div class="why_book_grid">
            <div>
              <h3>
Expert knowledge of safari destinations
.</h3>
              <p>
               The African wilderness is our personal playground. We were born and raised in Africa. We can tell you the best tree for a family picnic, the perfect place for a romantic proposal, and even the smell of a bush at sunrise or sunset.
              </p>
            </div>
            <div>
              <h3>Personalized safari experiences</h3>
              <p>
                Nothing excites us more than matching your safari ideas with our personal knowledge and experience. We make sure your safari is perfect because living through you is the best part of what we do.
              </p>
            </div>
            <div>
              <h3>No confines in choosing the best for our clients </h3>
              <p>
               We don't own or operate the lodges and camps we book. We are therefore always eager to put the interests of our clients first. Our company is operated by selected seasoned safari guides who are deployed to match the client's destination.
              </p>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="watch_videos">
        <div class="container text-center">
          <h2>Watch our videos to find out more</h2>
          <hr style="width: 100px; background-color: #996727;" />
          <div class="watch_videos_grid">
            <div>
              <iframe
                width="100%"
                height="450"
                src="https://www.youtube.com/embed/cKTezHceFwQ"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div>
              <div>

                <iframe
                  width="100%"
                  height="180"
                  src="https://www.youtube.com/embed/qOhmZwk1Ft4"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div>

                <iframe
                  width="100%"
                  height="180"
                  src="https://www.youtube.com/embed/5FVO3IHwIzw"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div>

                <iframe
                  width="100%"
                  height="180"
                  src="https://www.youtube.com/embed/BLhqxg1I26g"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="country_section">
        <div class="container text-center">
          <h2 class="mb-4">Top Rated East African Safari Countries</h2>
          <hr style="width: 100px; background-color: #996727;" />
          <div
            class="country_tours_grid"
            v-if="isLoading || destinations.length == 0"
          >
            <div
              v-for="i in 4"
              :key="i"
              class="skeleton-block skeleton-effect-wave"
            ></div>
          </div>
          <div
            v-else
            class="country_tours_grid"
          >
            <div data-aos="fade-up"
     data-aos-anchor-placement="top-bottom" data-aos-duration="1500"
              v-for="destination in destinations"
              :key="destination.id"
              @click="$router.push({ path: '/safari-packages', query: { whereTo: `${destination.country_title}`} })"
            >
              <img :src="destination.featured_photo">
              <div
                class="text-center"
                @click="$router.push({ path: '/safari-packages', query: { whereTo: `${destination.country_title}`} })"
              >
                <h2>{{ destination.country_title }} Tours</h2>
                <div class="country_text mt-2">
                  <span>Great wildlife in both forest and savannah.</span><br />
                  <span style="color: #996727; font-weight: 600;">Explore More <i class="el-icon-top-right"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

    <PackagesComponent />

  </div>
</template>

<script>
import HomeFirstArticleComponent from "../components/home/home-first-article-component.vue";
// import CarouselComponent from "../components/home/carousel-component.vue";
import PackagesComponent from "../components/home/packages-component.vue";

export default {
  components: {
    // CarouselComponent,
    PackagesComponent,
    HomeFirstArticleComponent,
  },

  data() {
    return {
      isLoading: false,
      destinations: [],
    };
  },

  mounted() {
    this.getDestinations();
  },

  methods: {
    verifyPhoneNUmber() {
      this.$router.push({ path: `verify-phone-number` });
    },

    async getDestinations() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `api/safari/destination/country/get_all`
        );
        if (request.data.success) {
          this.destinations = request.data.countries;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        return;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.why_book {
  padding-top: 50px;
  padding-bottom: 50px;
}
.why_book_grid {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 50px;
}
.why_book_grid > div {
}

.watch_videos {
  background-color: #eeeeee;
  padding-top: 50px;
  padding-bottom: 50px;
}
.watch_videos_grid {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 15px;
}
.watch_videos_grid > div {
  height: 450px;
}
.watch_videos_grid > div:nth-child(2) {
  height: 450px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  overflow-y: scroll;
}

.watch_videos_grid > div:nth-child(2) > div {
  height: 180px;
}

.country_section {
  background-color: #fff;
  padding-top: 50px;
  padding-bottom: 50px;
}
.why_lugave {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.why_lugave > div:first-child {
  /* background-color: #5c4b13; */
}
.why_lugave > div:nth-child(2) {
  padding: 20px 20px 20px 50px;
  background-color: white;
  display: flex;
  align-items: center;
}

.tent_adv img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.check-list {
  margin-top: 15px;
  padding-left: 1.2rem;
  padding: 0px;
}
.check-list li {
  position: relative;
  list-style-type: none;
  padding-left: 2.5rem;
  margin-bottom: 0.5rem;
}

.check-list li:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -2px;
  width: 5px;
  height: 11px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  border-color: #00a8a8;
  transform-origin: bottom left;
  transform: rotate(45deg);
}

.country_tours_grid {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.country_tours_grid > div {
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  height: 450px;
}

.country_tours_grid > div img {
  width: 100%;
  height: 450px;
  object-fit: cover;
  border-radius: 5px;
}

.country_tours_grid > div > div {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 10px 10px 50px 10px;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  color: white;
  border-radius: 0px 0px 5px 5px;
}

h2 {
  font-size: 1.5em;
  font-weight: 600;
}

h3 {
  font-size: 1.2em;
  font-weight: 600;
}

.country_tours_grid .country_text {
  font-size: 1em;
  font-weight: 300;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .why_book_grid {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 50px;
  }

  .watch_videos_grid {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  .watch_videos_grid > div {
    height: 450px;
  }
  .watch_videos_grid > div:nth-child(2) {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }

  .watch_videos_grid > div:nth-child(2) > div {
    height: 120px;
  }

  .country_tours_grid {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .country_tours_grid > div {
    height: 250px;
  }
  .country_tours_grid > div img {
    height: 250px;
  }

  h2 {
    font-size: 1.2em;
    font-weight: 600;
  }

  .country_tours_grid .country_text {
    font-size: 0.8em;
    font-weight: 300;
  }

  .country_tours_grid > div > div {
    padding: 10px 10px 30px 10px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .why_book_grid {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 50px;
  }

  .watch_videos_grid {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  .watch_videos_grid > div {
    height: 450px;
  }
  .watch_videos_grid > div:nth-child(2) {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }

  .watch_videos_grid > div:nth-child(2) > div {
    height: 120px;
  }

  .country_tours_grid {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .country_tours_grid > div {
    height: 250px;
  }
  .country_tours_grid > div img {
    height: 250px;
  }

  h2 {
    font-size: 1.2em;
    font-weight: 600;
  }

  .country_tours_grid .country_text {
    font-size: 0.8em;
    font-weight: 300;
  }

  .country_tours_grid > div > div {
    padding: 10px 10px 30px 10px;
  }
  .why_lugave {
    display: grid;
    grid-template-columns: 1fr;
  }
  .why_lugave > div:first-child {
    /* background-color: #5c4b13; */
  }
  .why_lugave > div:nth-child(2) {
    padding: 50px 20px 20px 50px;
    background-color: white;
    display: flex;
    align-items: center;
  }
}
</style>
